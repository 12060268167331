import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { AUTH_MODAL_PAGES, SPOTLIGHT, SUBSCRIBE_MODAL_PAGES } from '../../constants/enums';
import {
  SUBSCRIBE_CHECKOUT_LINK_ERROR,
  SUBSCRIBE_CHECKOUT_LINK_OPENED,
  SUBSCRIBE_MODAL_CLOSED,
} from '../../constants/events';
import { BACKSTAGE_URL } from '../../constants/variables';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import vipCard from '../../public/icons/vip_card.png';
import { useCreateSubscriptionCheckoutMutation } from '../../store/api';
import { clearModalsSlice, openAuthModal } from '../../store/modalsSlice';
import { CheckoutResponseDto } from '../../types/payments';
import analyticsEvent from '../../utils/logEvent';
import Spotlight from '../ui/Spotlight';

const modalContentStyle = {
  position: 'relative',
  overflow: 'hidden',
  minHeight: { base: '36rem', lg: '42rem' },
} as const;

const listStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: { base: 'column', md: 'row' },
  justifyContent: 'space-between',
  marginLeft: { sm: '20%', md: 'auto' },
  marginRight: { md: -8 },
  marginTop: 4,
  marginBottom: 6,

  '> li': {
    width: { md: '40%' },
    listStyleImage: 'url("../icons/crown.svg")',
  },
} as const;

const successBodyStyle = {
  textAlign: 'left',
  maxW: 660,
  paddingTop: 16,
} as const;

const successVipImageStyle = {
  textAlign: 'right',
  marginLeft: 'auto',
  width: { base: 48, lg: 'auto' },
  height: { base: 36, lg: 'auto' },
} as const;

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '');

export default function SubscribeModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals, user } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();
  const t = useTranslations('Global');
  const tAlt = useTranslations('Global.alt');
  const [createSubscriptionCheckout] = useCreateSubscriptionCheckoutMutation();
  const [subscriptionCheckoutError, setSubscriptionCheckoutError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (modals.subscribeModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.subscribeModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    onClose();
    analyticsEvent(SUBSCRIBE_MODAL_CLOSED);
    dispatch(clearModalsSlice());
  }

  async function subscriptionCheckout() {
    setLoading(true);
    const currentPath = window.location.pathname;
    const successPath = currentPath.includes('/backstage/')
      ? '/backstage/vip-videos'
      : '/vip-videos';
    const response = await createSubscriptionCheckout({
      successPath: successPath,
      cancelPath: window.location.pathname,
    });

    if ('data' in response) {
      const data = response.data as CheckoutResponseDto;
      const checkoutLink = data.checkoutUrl;
      analyticsEvent(SUBSCRIBE_CHECKOUT_LINK_OPENED);
      (window as any).location.href = checkoutLink;
    }

    if ('error' in response) {
      analyticsEvent(SUBSCRIBE_CHECKOUT_LINK_ERROR, { error: response.error });
      setSubscriptionCheckoutError(t('subscribeModal.errors.subscriptionCheckoutLink'));
      throw response.error;
    }
  }

  const spotlight =
    modals.subscribeModalPage === SUBSCRIBE_MODAL_PAGES.DETAILS ? (
      <Spotlight
        option={SPOTLIGHT.LEFT_2}
        style={{
          top: 'unset',
          bottom: { base: '-40vw', md: '-40vw', lg: '-30vw' },
        }}
      />
    ) : (
      <Spotlight
        option={SPOTLIGHT.RIGHT_2}
        style={{
          width: { base: '160%', md: '100%' },
          top: { base: '35%', md: '10%' },
          bottom: 0,
          right: { md: '-30%' },
          left: { base: '20%', md: '30%' },
        }}
      />
    );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="5xl">
      <ModalOverlay />
      <ModalContent data-test-id="subscribe-modal-content" sx={modalContentStyle}>
        {spotlight}
        <ModalCloseButton data-test-id="subscribe-modal-close-button" />
        {loading ? (
          <Spinner m="auto" />
        ) : (
          modals.subscribeModalPage === SUBSCRIBE_MODAL_PAGES.DETAILS && (
            <ModalBody zIndex={1}>
              <Image
                src={vipCard}
                width={100}
                height={82}
                objectFit="contain"
                alt={tAlt('vipCard')}
              />

              <Heading
                as="h2"
                fontSize={{ base: '1.5rem', md: '1.875rem', xl: '2rem', '2xl': '2.25rem' }}
                marginX="auto"
              >
                {t('subscribeModal.details.heading')}
              </Heading>
              <Text
                fontSize={{ base: '1rem', md: '1.175rem', xl: '1.2rem', '2xl': '1.25rem' }}
                fontWeight={700}
                lineHeight="120%"
                textTransform="uppercase"
                mb={6}
              >
                {t('subscribeModal.details.sub-heading')}
              </Text>

              <Center>
                <VStack gap={6} mb={{ base: 4, md: 6 }}>
                  <Stack direction={{ base: 'column', md: 'row' }} gap={{ base: 4, md: 32 }}>
                    <Box width="20rem" textAlign="left">
                      <Text fontSize={{ base: '0.9rem', md: '1rem' }}>
                        {t.rich('subscribeModal.details.accessOptions.choreographers', {
                          bold: (children) => <strong>{children}</strong>,
                        })}
                      </Text>
                    </Box>
                    <Box width="20rem" textAlign="left">
                      <Text>
                        {t.rich('subscribeModal.details.accessOptions.studioRoutines', {
                          bold: (children) => <strong>{children}</strong>,
                        })}
                      </Text>
                    </Box>
                  </Stack>
                  <Stack direction={{ base: 'column', md: 'row' }} gap={{ base: 6, md: 32 }}>
                    <Box width="20rem" textAlign="left">
                      <Text fontSize={{ base: '0.9rem', md: '1rem' }}>
                        {t.rich('subscribeModal.details.accessOptions.mindsetCoaching', {
                          bold: (children) => <strong>{children}</strong>,
                        })}
                      </Text>
                    </Box>
                    <Box width="20rem" textAlign="left">
                      <Text fontSize={{ base: '0.9rem', md: '1rem' }}>
                        {t.rich('subscribeModal.details.accessOptions.yoga', {
                          bold: (children) => <strong>{children}</strong>,
                        })}
                      </Text>
                    </Box>
                  </Stack>
                </VStack>
              </Center>

              <Text
                fontSize={{
                  base: '1.175rem',
                  md: '1.2rem',
                  lg: '1.5rem',
                  xl: '1.625rem',
                  '2xl': '1.875rem',
                }}
                textTransform="uppercase"
                fontWeight={700}
                lineHeight="120%"
                mb={0}
                mt={2}
              >
                {t('subscribeCTA.firstMonth')}
              </Text>
              <Text textTransform="uppercase" fontSize={{ base: 'sm', md: 'md' }} color="pink.500">
                {t('subscribeCTA.price')}
              </Text>

              {subscriptionCheckoutError && (
                <Text
                  id="error"
                  data-test-id="subscription-checkout-error"
                  color="pink.500"
                  fontWeight="600"
                >
                  {subscriptionCheckoutError}
                </Text>
              )}

              <Button
                onClick={() =>
                  user.id ? subscriptionCheckout() : openAuthModal(AUTH_MODAL_PAGES.LOGIN)
                }
                data-test-id="subscribe-modal-subscribe-button"
              >
                {t('subscribeCTA.button')}
              </Button>
            </ModalBody>
          )
        )}

        {modals.subscribeModalPage === SUBSCRIBE_MODAL_PAGES.SUCCESS && (
          <ModalBody sx={successBodyStyle}>
            <Heading as="h2" size="h-3xl">
              {t('subscribeModal.success.heading')}
            </Heading>
            <Text size="h-lg">{t('subscribeModal.success.headline')}</Text>
            <Text fontSize="lg" mb={6}>
              {t('subscribeModal.success.description')}
            </Text>
            <NextLink href={`${BACKSTAGE_URL}/vip-videos`} passHref>
              <Button onClick={closeModal}>{t('subscribeModal.success.button')}</Button>
            </NextLink>
            <Box sx={successVipImageStyle}>
              <Image
                src={vipCard}
                width={345}
                height={285}
                objectFit="contain"
                alt={tAlt('vipCard')}
              />
            </Box>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
