import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { clearModalsSlice } from '../../store/modalsSlice';
import EventCreateForm from '../forms/EventCreateForm';

export default function EventCreateModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();
  const t = useTranslations('BossHub.createEventModal');

  useEffect(() => {
    if (modals.eventCreateModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.eventCreateModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    // analyticsEvent(VIDEO_MODAL_CLOSED);
    dispatch(clearModalsSlice());
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={'4xl'}>
      <ModalOverlay />
      <ModalContent
        data-test-id="create-event-modal-content"
        p={{ base: '1rem', md: '4rem' }}
        borderRadius="1.375rem"
      >
        <ModalCloseButton zIndex={2} data-test-id="create-event-modal-close-button" />
        <Heading px="1.45rem" size="h-md">
          {t('heading')}
        </Heading>
        <ModalBody>
          <EventCreateForm closeModal={closeModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
