import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EVENT_STATUS, TICKET_STATUS } from '../constants/enums';
import { ApiErrorResponse } from '../types/ApiErrors';
import { EventResponse } from '../types/events';
import { TicketResponse } from '../types/tickets';
import { api } from './api';

export interface EventsSlice extends EventResponse {
  tickets: TicketResponse[];
}

// Routines slice stores the last retrieved routines data
// Populated as required/can be partially populated, e.g. if current set is required on first page but archive isn't
export const initialState: EventsSlice[] = [];

const slice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    clearEventsSlice(state) {
      return initialState;
    },

    setEventCancelled(state, action: PayloadAction<{ eventId: string }>) {
      const eventIndex = state.findIndex((event) => event.id === action.payload.eventId);
      state[eventIndex].status = EVENT_STATUS.CANCELLED;
      return state;
    },

    setTicketToCancelledList(state, action: PayloadAction<{ eventId: string; ticketId: string }>) {
      const eventIndex = state.findIndex((event) => event.id === action.payload.eventId);
      const event = state[eventIndex];
      const ticketIndex = event.tickets.findIndex(
        (ticket) => ticket.id === action.payload.ticketId,
      );
      state[eventIndex].tickets[ticketIndex].status = TICKET_STATUS.CANCELLED;
      const numAttendees = state[eventIndex].numAttendees;
      state[eventIndex].numAttendees = numAttendees ? numAttendees - 1 : undefined;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getMyBossEvents.matchFulfilled, (state, { payload }) => {
      if (payload instanceof Array) {
        state = payload.map((e: EventResponse) => {
          return { ...e, tickets: [] };
        });
      }
      return state;
    });
    builder.addMatcher(api.endpoints.getEventTickets.matchFulfilled, (state, { payload }) => {
      if (payload instanceof Array && payload.length > 0) {
        const eventIndex = state.findIndex((event) => event.id === payload[0].eventId);
        state[eventIndex].tickets = payload;
      }
      return state;
    });
    builder.addMatcher(api.endpoints.createEvent.matchFulfilled, (state, { payload }) => {
      if (!(payload instanceof ApiErrorResponse)) {
        state.push({ ...payload, tickets: [] });
        state.sort(function compare(eventA: EventResponse, eventB: EventResponse) {
          var dateA: number = new Date(eventA.startDateTime).getTime();
          var dateB: number = new Date(eventB.startDateTime).getTime();
          return dateA - dateB;
        });
      }
    });
  },
});

const { actions, reducer } = slice;
export const { clearEventsSlice, setEventCancelled, setTicketToCancelledList } = actions;
export default reducer;
