import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { EVENT_BOOK_PAGES } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { clearEventSlice } from '../../store/eventSlice';
import { clearModalsSlice } from '../../store/modalsSlice';
import EventBookDetails from '../events/EventBookDetails';
import EventBookSuccess from '../events/EventBookSuccess';

export default function EventBookModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);

  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '');

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (modals.eventBookModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.eventBookModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    // analyticsEvent(VIDEO_MODAL_CLOSED);
    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());
  }

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      size={isMobile ? 'full' : modals.bookModalPage === EVENT_BOOK_PAGES.DETAILS ? '5xl' : '4xl'}
    >
      <ModalOverlay />
      <ModalContent
        data-test-id="event-book-modal-content"
        p={0}
        borderRadius={{
          base: 0,
          lg: modals.bookModalPage === EVENT_BOOK_PAGES.DETAILS ? '1.375rem' : '0.75rem',
        }}
        h="fit-content"
        overflow="hidden"
      >
        <ModalCloseButton
          top="1rem"
          right="1rem"
          zIndex={2}
          borderRadius="8rem"
          bgColor="#000000CC"
          textColor="white"
          data-test-id="book-event-modal-close-button"
        />
        <Box overflow="auto" w="100%" h="100%">
          {modals.bookModalPage === EVENT_BOOK_PAGES.DETAILS && <EventBookDetails />}
          {modals.bookModalPage === EVENT_BOOK_PAGES.SUCCESS && <EventBookSuccess />}
        </Box>
      </ModalContent>
    </Modal>
  );
}
