import { Box, Button, Heading, Spinner, Text } from '@chakra-ui/react';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { EmailAuthProvider, GoogleAuthProvider, UserCredential } from 'firebase/auth';
import { useTranslations } from 'next-intl';
import { ReactElement, useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebaseApp, firebaseAuth } from '../../config/firebase';
import { AUTH_MODAL_PAGES } from '../../constants/enums';
import { USER_LOGGED_IN, USER_SIGNED_UP, USER_SIGNED_UP_ERROR } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { setMixpanelIdentity } from '../../pages/_app';
import { useCreateUserMutation } from '../../store/api';
import { setAuthModalPage } from '../../store/modalsSlice';
import { setUserAuth, setUserLoading } from '../../store/userSlice';
import { User } from '../../types/users';
import { convertStringToDate, dateTimeNow } from '../../utils/dateTime';
import analyticsEvent from '../../utils/logEvent';

interface Props {
  closeModal: () => void;
}

export default function SignUpForm(props: Props) {
  const { closeModal } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const [widget, setWidget] = useState<ReactElement | null>(null);

  const [createUser] = useCreateUserMutation();
  const { cookiesState, modals } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();
  const t = useTranslations('Global.auth.signUp');

  useEffect(() => {
    if (modals.authModalOpen && modals.authModalPage === AUTH_MODAL_PAGES.SIGN_UP) {
      const firebaseSignUpUiConfig: firebaseui.auth.Config = {
        signInFlow: 'popup',
        signInOptions: [
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            fullLabel: 'Sign up with email',
          },
          {
            provider: GoogleAuthProvider.PROVIDER_ID,
            fullLabel: 'Sign up with Google',
          },
        ],
        callbacks: {
          signInSuccessWithAuthResult: (authResult: UserCredential) => {
            completeCreateUser(authResult).then(() => {});
            return false; // required callback response instead of providing redirect url
          },
        },
      };
      setWidget(
        <StyledFirebaseAuth uiConfig={firebaseSignUpUiConfig} firebaseAuth={firebaseAuth} />,
      );
    }

    if (!modals.authModalOpen && modals.authModalPage !== AUTH_MODAL_PAGES.SIGN_UP) {
      setWidget(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modals.authModalOpen, modals.authModalPage]);

  useEffect(() => {
    // Set user state to loading so that get user isn't called on _app.tsx
    // before the user is created here.
    dispatch(setUserLoading(true));

    return () => {
      dispatch(setUserLoading(false));
    };
  }, [dispatch]);

  const completeCreateUser = async (authResult: UserCredential) => {
    setLoading(true);
    try {
      const token = await authResult.user.getIdToken();
      const uid = authResult.user.uid;
      const email = authResult.user.email || '';
      const firebaseAnalytics = getAnalytics(firebaseApp);

      if (cookiesState.analyticsAccepted) {
        setUserId(firebaseAnalytics, uid);
      }
      await dispatch(setUserAuth({ uid: uid, token, email: email }));

      const response = await createUser({
        name: authResult.user.displayName || '',
      });

      if ('data' in response) {
        const data = response.data as User;
        const now = dateTimeNow();
        const createdAt = convertStringToDate(data.createdAt);

        setMixpanelIdentity(data.id);

        if (now.getTime() - createdAt.getTime() < 9000) {
          // User created up within 2 mins
          analyticsEvent(USER_SIGNED_UP);
          dispatch(setAuthModalPage(AUTH_MODAL_PAGES.SIGN_UP_PROFILE));
        } else {
          // User created up more than 2 mins ago, existing user
          // This occurs when a user is redirected within the firebaseui when attempting to sign up with an existing account
          // but they're still on the sign up tab and using the sign up callbacks.signInSuccessWithAuthResult below
          analyticsEvent(USER_LOGGED_IN);
          closeModal();
        }
      }

      if ('error' in response) {
        setLoading(false);
        analyticsEvent(USER_SIGNED_UP_ERROR, { error: response.error });
        setFormError(t('form.error'));
        throw response.error;
      }
    } catch (error) {
      setLoading(false);
      analyticsEvent(USER_SIGNED_UP_ERROR, { error: error });
      setFormError(t('form.error'));
      throw error;
    }
  };

  if (loading) {
    return <Spinner m="auto" mt="6rem" color="pink.500"></Spinner>;
  }
  return (
    <Box data-test-id="sign-up-form">
      <Heading as="h2" size="h-xl">
        {t('heading')}
      </Heading>
      <Text mb={6}>{t('description')}</Text>

      {modals.authModalPage === AUTH_MODAL_PAGES.SIGN_UP && <>{widget}</>}

      {formError && (
        <Text id="error" color="pink.500" fontWeight="600">
          {formError}
        </Text>
      )}
      <Button
        onClick={closeModal}
        data-test-id="sign-up-form-skip-button"
        variant="unstyled"
        mt={4}
      >
        {t('skipButton')}
      </Button>
    </Box>
  );
}
