import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { EVENT_STATUS, TICKET_STATUS } from '../../constants/enums';
import { useTypedSelector } from '../../hooks/store';
import { TicketResponse } from '../../types/tickets';

interface Props {
  ticket: TicketResponse;
  setQueenToRemove: (ticketId: string) => void;
  key: number;
}

export default function EventAttendeeCard(props: Props) {
  const { firstName, lastName, email } = props.ticket.queenDetails;
  const { status, id } = props.ticket;
  const { setQueenToRemove } = props;

  const { event } = useTypedSelector((state) => state);

  const t = useTranslations('BossHub.eventAttendeeCard');

  function onClickRemoveQueen() {
    setQueenToRemove(id);
  }

  return (
    <>
      <Flex
        p="1rem"
        bgColor="white"
        justifyContent="center"
        borderRadius="0.75rem"
        direction="row"
        data-test-id="event-attendee-card"
        w="full"
      >
        <Box>
          <Text
            fontWeight={700}
            fontSize="1rem"
            textTransform="uppercase"
            mb={0}
            data-test-id="queen-name"
          >{`${firstName} ${lastName}`}</Text>
          <Text fontSize="1rem" data-test-id="queen-email">
            {email}
          </Text>
        </Box>
        <Spacer />
        {status === TICKET_STATUS.CANCELLED ? (
          <Text textColor="pink.500" data-test-id="removed-text" fontSize="14px">
            removed
          </Text>
        ) : (
          <Button
            colorScheme="gray"
            size="xs"
            onClick={onClickRemoveQueen}
            data-test-id="remove-queen-button"
            disabled={event.status === EVENT_STATUS.CANCELLED}
          >
            {t('removeButton')}
          </Button>
        )}
      </Flex>
    </>
  );
}
