import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import NextLink from 'next/link';
import { useEffect } from 'react';
import { AUTH_MODAL_PAGES, SUBSCRIBE_MODAL_PAGES } from '../../constants/enums';
import {
  AUTH_MODAL_CLOSED,
  AUTH_MODAL_LOGIN_VIEWED,
  AUTH_MODAL_SIGN_UP_VIEWED,
} from '../../constants/events';
import { BACKSTAGE_URL } from '../../constants/variables';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import find from '../../public/icons/find.png';
import vip from '../../public/icons/vip.png';
import { clearModalsSlice, openSubscribeModal, setAuthModalPage } from '../../store/modalsSlice';
import analyticsEvent from '../../utils/logEvent';
import LoginForm from '../forms/LoginForm';
import SignUpForm from '../forms/SignUpForm';
import UserProfileForm from '../forms/UserProfileForm';

const modalContentStyle = {
  minHeight: { base: '34rem', lg: '40.5rem' },
};

const signupSuccessHeaderStyle = {
  maxWidth: '100%',
  marginX: 'auto',
  marginBottom: 6,
} as const;

const successBoxStyle = {
  backgroundColor: 'gray.50',
  width: { base: '18rem', md: '20rem' },
  textAlign: 'left',
  padding: 6,
};

export default function AuthModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();
  const t = useTranslations('Global.auth');

  useEffect(() => {
    if (modals.authModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.authModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    analyticsEvent(AUTH_MODAL_CLOSED);
    dispatch(clearModalsSlice());
  }

  function onTabChange(index: number) {
    if (index === 0) {
      analyticsEvent(AUTH_MODAL_SIGN_UP_VIEWED, { origin_component: 'auth_modal_tab' });
      dispatch(setAuthModalPage(AUTH_MODAL_PAGES.SIGN_UP));
    } else {
      analyticsEvent(AUTH_MODAL_LOGIN_VIEWED, { origin_component: 'auth_modal_tab' });
      dispatch(setAuthModalPage(AUTH_MODAL_PAGES.LOGIN));
    }
  }

  function SignUpSuccess() {
    return (
      <>
        <ModalBody data-test-id="sign-up-success" mt={5}>
          <Heading
            as="h2"
            fontSize={{ base: '1.5rem', md: '1.875rem', xl: '2rem', '2xl': '2.25rem' }}
            sx={signupSuccessHeaderStyle}
            whiteSpace="initial"
          >
            {t('signUp.success.heading')}
          </Heading>
          <Center mb={6}>
            <Text
              fontSize={{ base: '1rem', md: '1.175rem', xl: '1.2rem', '2xl': '1.25rem' }}
              fontWeight={700}
              lineHeight="120%"
              textTransform="uppercase"
              mb={6}
              maxWidth="24rem"
            >
              {t('signUp.success.description')}
            </Text>
          </Center>
          <Center>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '1.5rem', md: '2.5rem' }}
              mb={4}
            >
              <Box sx={successBoxStyle}>
                <Box paddingY={3}>
                  <Image src={vip} objectFit="contain" alt="vip" />
                </Box>
                <Text color="pink.500" mb={4} size="h-md">
                  {t('signUp.success.vip.heading')}
                </Text>
                <Box maxWidth="95%" mb={4}>
                  <Text fontSize="1.125rem">
                    {t.rich('signUp.success.vip.description', {
                      bold: (children) => <strong>{children}</strong>,
                    })}
                  </Text>
                </Box>
                <Button
                  size="sm"
                  onClick={() => dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS))}
                >
                  {t('signUp.success.vip.button')}
                </Button>
              </Box>
              <Box sx={successBoxStyle}>
                <Box paddingY={1}>
                  <Image src={find} objectFit="contain" alt="find" />
                </Box>
                <Text color="pink.500" mb={4} size="h-md">
                  {t('signUp.success.findClass.heading')}
                </Text>
                <Box maxWidth="95%" mb={4}>
                  <Text fontSize="1.125rem">{t('signUp.success.findClass.description')}</Text>
                </Box>
                <NextLink href={`${BACKSTAGE_URL}/queen-hub`} passHref>
                  <Button size="sm" onClick={closeModal}>
                    {t('signUp.success.findClass.button')}
                  </Button>
                </NextLink>
              </Box>
            </Stack>
          </Center>
          <Button
            onClick={closeModal}
            data-test-id="sign-up-profile-form-skip-button"
            variant="unstyled"
            mt={6}
          >
            {t('profile.skipButton')}
          </Button>
        </ModalBody>
      </>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="5xl">
      <ModalOverlay />
      <ModalContent data-test-id="auth-modal-content" sx={modalContentStyle}>
        <ModalCloseButton data-test-id="auth-modal-close-button" />
        <ModalBody maxWidth="44rem">
          {modals.authModalPage === AUTH_MODAL_PAGES.SIGN_UP_SUCCESS ? (
            <SignUpSuccess />
          ) : modals.authModalPage === AUTH_MODAL_PAGES.SIGN_UP_PROFILE ? (
            <>
              <Heading as="h2" size="h-xl">
                {t('profile.heading')}
              </Heading>
              <Text mb={10}>{t('profile.description')}</Text>
              <UserProfileForm closeModal={closeModal} isSignUp={true} />
            </>
          ) : (
            <Tabs
              colorScheme="pink"
              defaultIndex={modals.authModalPage === AUTH_MODAL_PAGES.LOGIN ? 1 : 0}
              onChange={(index) => onTabChange(index)}
            >
              <TabList>
                <Tab data-test-id="sign-up-tab">{t('signUp.tab')}</Tab>
                <Tab data-test-id="login-tab">{t('login.tab')}</Tab>
              </TabList>

              <TabPanels>
                <TabPanel textAlign="center">
                  <SignUpForm closeModal={closeModal} />
                </TabPanel>
                <TabPanel textAlign="center">
                  <LoginForm closeModal={closeModal} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
