const crypto = require('crypto');

export function capitalizeFirstLetter(str: string | null) {
  if (!str) {
    return '';
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function generateRandomString(numChars: number) {
  return crypto.randomBytes(numChars).toString('hex');
}
