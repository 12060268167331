import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Place } from '../types/places';
import { bossQualifications } from '../types/users';
import { api } from './api';

export interface UserSlice {
  loading: boolean;
  authLoading: boolean;
  id: string | null;
  token: string | null;
  firebaseUid: string | null;
  firstName: string;
  lastName: string;
  email: string;
  homeLocation: Place | null;
  instagramHandle: string | null;
  birthday: Date | null;
  contactPermission: boolean;
  isVip: boolean;
  bossQualifications: bossQualifications | null;
  stripeConnectAccountId: string | null;
  stripeChargesEnabled: boolean;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export const initialState: UserSlice = {
  loading: false, // user model or general state loading
  authLoading: true, // firebase auth loading, true for initial load
  id: null,
  token: null,
  firebaseUid: null,
  firstName: '',
  lastName: '',
  email: '',
  homeLocation: null,
  instagramHandle: null,
  birthday: null,
  contactPermission: false,
  isVip: false,
  bossQualifications: null,
  stripeConnectAccountId: null,
  stripeChargesEnabled: false,
  createdAt: null,
  updatedAt: null,
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    clearUserSlice(state) {
      return initialState;
    },
    updateUser(state, action: PayloadAction<Partial<UserSlice>>) {
      return Object.assign({}, state, action.payload);
    },
    setUserAuth(state, action: PayloadAction<{ uid: string; token: string; email: string }>) {
      state.firebaseUid = action.payload.uid;
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.authLoading = false;
    },
    setUserLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setUserAuthLoading(state, action: PayloadAction<boolean>) {
      state.authLoading = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.createUser.matchFulfilled, (state, { payload }) => {
      state = Object.assign({}, state, payload);
      state.loading = false;
      return state;
    });
    builder.addMatcher(api.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state = Object.assign({}, state, payload);
      state.loading = false;
      return state;
    });
    builder.addMatcher(api.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
      state = Object.assign({}, state, payload);
      state.loading = false;
      return state;
    });
  },
});

const { actions, reducer } = slice;
export const { clearUserSlice, setUserAuth, setUserLoading, setUserAuthLoading, updateUser } =
  actions;
export default reducer;
