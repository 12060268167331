import { Button, Flex, Text } from '@chakra-ui/react';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import { useAppDispatch } from '../../hooks/store';
import { clearEventSlice } from '../../store/eventSlice';
import { clearModalsSlice } from '../../store/modalsSlice';

export default function EventBookSuccess() {
  const t = useTranslations('QueenHub.eventBook.success');
  const dispatch: any = useAppDispatch();

  function closeModal() {
    // analyticsEvent(VIDEO_MODAL_CLOSED);
    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());
  }

  return (
    <Flex
      w="100%"
      py="12rem"
      px="2rem"
      overflow="hidden"
      alignItems="center"
      direction="column"
      gap="0.5rem"
    >
      <FontAwesomeIcon
        icon={faCheckCircle}
        color="#25855A"
        width="70px"
        height="70px"
        size="5x"
        data-test-id="check-circle-icon"
      />
      <Text
        fontWeight={700}
        fontSize="1.875rem"
        textTransform="uppercase"
        data-test-id="success-heading"
      >
        {t('heading')}
      </Text>
      <Text fontSize="1.125rem" maxW="60%" textAlign="center" data-test-id="success-description">
        {t('description')}
      </Text>
      <Button size="md" colorScheme="pink" onClick={closeModal}>
        {t('exploreButton')}
      </Button>
    </Flex>
  );
}
